<template>
  <div>
    <CRow>
      <CCol sm="12"> 
        <EntityTabsContent v-if="$route.params.id" />
        <EntityPageContent v-else />
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'EntityPage',
  components: {
    EntityPageContent: () => import('./EntityPageContent'),
    EntityTabsContent: () => import('./EntityTabsContent')
  }
}
</script>
